import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, FormLabel, Input, Heading, Text, VStack } from '@chakra-ui/react';

const Register = () => {
    const [formData, setFormData] = useState({ username: '', email: '', password: '' });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.senc.in/auth/register', formData);
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Registration failed');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bgGradient="linear(to-r, gray.700, gray.900)"
            color="white"
            p={4}
        >
            <Heading as="h2" size="xl" mb={6} animation="fadeIn 2s ease-in-out">
                Register
            </Heading>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <FormControl id="username" isRequired>
                        <FormLabel>Username</FormLabel>
                        <Input
                            type="text"
                            name="username"
                            placeholder="Username"
                            value={formData.username}
                            onChange={handleChange}
                            focusBorderColor="teal.400"
                        />
                    </FormControl>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            focusBorderColor="teal.400"
                        />
                    </FormControl>
                    <FormControl id="password" isRequired>
                        <FormLabel>Password</FormLabel>
                        <Input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            focusBorderColor="teal.400"
                        />
                    </FormControl>
                    <Button type="submit" colorScheme="teal" size="md" width="full">
                        Register
                    </Button>
                </VStack>
            </form>
            {message && (
                <Text mt={4} fontSize="lg" animation="fadeIn 1s ease-in-out">
                    {message}
                </Text>
            )}
        </Box>
    );
};

export default Register;
