import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, VStack, HStack } from '@chakra-ui/react';

function ViewBrackets() {
    const [rounds, setRounds] = useState([]);
    const [teams, setTeams] = useState({});
    const { tournamentId } = useParams();

    useEffect(() => {
        const fetchBrackets = async () => {
            try {
                // Fetch brackets
                const response = await fetch(`https://api.senc.in/api/tournaments/${tournamentId}/brackets`);
                const brackets = await response.json();

                // Fetch teams
                const teamResponse = await fetch(`https://api.senc.in/api/tournaments/${tournamentId}/teams`);
                const teams = await teamResponse.json();

                // Map team IDs to names
                const teamMap = teams.reduce((acc, team) => {
                    acc[team.id] = team.name;
                    return acc;
                }, {});

                setTeams(teamMap);

                // Group matches by round
                const roundsMap = brackets.reduce((acc, match) => {
                    if (!acc[match.round]) acc[match.round] = [];
                    acc[match.round].push(match);
                    return acc;
                }, {});

                const formattedRounds = Object.entries(roundsMap).map(([round, matches]) => ({
                    round: parseInt(round),
                    matches: matches.map(match => ({
                        id: `${match.tournamentId}-${match.round}-${match.matchNumber}`, // Ensure unique IDs
                        name: `Match ${match.matchNumber}`,
                        sides: {
                            home: {
                                team: { id: match.team1Id, name: teamMap[match.team1Id] || `Team ${match.team1Id}` },
                                score: { score: match.team1Score || 0 },
                            },
                            visitor: {
                                team: { id: match.team2Id, name: teamMap[match.team2Id] || `Team ${match.team2Id}` },
                                score: { score: match.team2Score || 0 },
                            },
                        },
                        winnerId: match.winnerId,
                    }))
                }));

                setRounds(formattedRounds.sort((a, b) => a.round - b.round));
            } catch (error) {
                console.error('Error fetching brackets or teams:', error);
            }
        };

        fetchBrackets();
    }, [tournamentId]);

    const MatchBox = ({ match }) => (
        <Box p={2} borderWidth="1px" borderRadius="md" bg="gray.700" width="200px">
            <Text>{match.sides.home.team.name} ({match.sides.home.score.score})</Text>
            <Text>vs</Text>
            <Text>{match.sides.visitor.team.name} ({match.sides.visitor.score.score})</Text>
            {match.winnerId && (
                <Text mt={2} fontWeight="bold">
                    Winner: {teams[match.winnerId] || `Team ${match.winnerId}`}
                </Text>
            )}
        </Box>
    );

    return (
        <Box pt={16} p={40} bg="gray.800" color="white" minH="100vh" overflowX="auto">
            <HStack spacing={8} align="flex-start">
                {rounds.map((round) => (
                    <VStack key={round.round} spacing={4} align="center">
                        <Text fontWeight="bold">Round {round.round}</Text>
                        {round.matches.map((match) => (
                            <MatchBox key={match.id} match={match} />
                        ))}
                    </VStack>
                ))}
            </HStack>
        </Box>
    );
}

export default ViewBrackets;
