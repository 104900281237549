import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Flex, Image, Text, IconButton, VStack, useMediaQuery } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from '../logos/senc.png';

const NavBar = () => {
    const [serverStatus, setServerStatus] = useState('offline');
    const [version, setVersion] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkServerStatus = async () => {
            try {
                const response = await fetch('https://api.senc.in/api/server-status');
                setServerStatus(response.ok ? 'online' : 'offline');
            } catch (error) {
                setServerStatus('offline');
            }
        };

        const fetchVersion = async () => {
            try {
                const response = await fetch('https://api.senc.in/api/version');
                if (response.ok) {
                    const data = await response.json();
                    setVersion(data.version);
                }
            } catch (error) {
                console.error('Failed to fetch version:', error);
            }
        };

        checkServerStatus();
        fetchVersion();

        const interval = setInterval(checkServerStatus, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        setIsLoggedIn(!!token);

        const handleLoginStateChange = () => {
            const token = sessionStorage.getItem('token');
            setIsLoggedIn(!!token);
        };

        window.addEventListener('loginStateChange', handleLoginStateChange);

        return () => {
            window.removeEventListener('loginStateChange', handleLoginStateChange);
        };
    }, []);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('isAdmin');
        setIsLoggedIn(false);
        navigate('/login');
        window.dispatchEvent(new Event('loginStateChange'));
    };

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="1rem 2rem"
            bg="rgba(0, 0, 0, 0.5)" // Semi-transparent background
            backdropFilter="blur(10px)" // Blur effect
            color="white"
            boxShadow="md"
            position="fixed"
            width="100%"
            zIndex="1000"
        >
            <Flex align="center">
                <Image src={logo} alt="Logo" height="40px" marginRight="1rem" />
            </Flex>
            <IconButton
                display={{ base: 'block', md: 'none' }}
                icon={menuOpen ? <CloseIcon /> : <HamburgerIcon />}
                onClick={toggleMenu}
                variant="outline"
                aria-label="Toggle Navigation"
            />
            <Flex
                direction={{ base: 'column', md: 'row' }}
                display={{ base: menuOpen ? 'flex' : 'none', md: 'flex' }}
                width={{ base: 'full', md: 'auto' }}
                alignItems="center"
                flexGrow={1}
            >
                <NavItem to="/">Home</NavItem>
                <NavItem to="/dashboard">Dashboard</NavItem>
                {isLoggedIn ? (
                    <NavItem as="button" onClick={handleLogout}>Logout</NavItem>
                ) : (
                    <>
                        <NavItem to="/login">Login</NavItem>
                        <NavItem to="/register">Register</NavItem>
                    </>
                )}
            </Flex>
            <VStack spacing={1} align="center">
                <Box width="10px" height="10px" borderRadius="full" bg={serverStatus === 'online' ? 'green.500' : 'red.500'} />
                <Text fontSize="sm" textTransform="capitalize">{serverStatus}</Text>
                <Text fontSize="xs" color="gray.300">{version}</Text>
            </VStack>
        </Flex>
    );
};

const NavItem = ({ to, children, ...props }) => (
    <Box as={to ? Link : 'button'} to={to} padding="0.5rem 1rem" borderRadius="md" _hover={{ bg: 'teal.600' }} margin={{ base: '0.5rem 0', md: '0 0.5rem' }} {...props}>
        {children}
    </Box>
);

export default NavBar;
