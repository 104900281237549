import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Heading, Button, Flex, Link, Text, VStack, HStack, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import axios from 'axios';
import logo from '../logos/senc.png'; // Adjust the path to your logo image

const Home = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://api.senc.in/api/tournaments');
                setEvents(response.data);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <Box
            position="relative"
            textAlign="center"
            p="2rem"
            pt="6rem" // Added top padding to ensure content is not covered by the navbar
            color="white"
            minH="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            fontFamily="Arial, sans-serif"
        >
            {/* Background Video */}
            <video
                autoPlay
                loop
                muted
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                }}
                onLoadedData={() => console.log('Video loaded successfully')}
                onError={(e) => console.error('Error loading video:', e)}
            >
                <source src="https://docs.onlinehub.co.in/senchome.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Overlay for blur effect */}
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bg="rgba(0, 0, 0, 0.5)"
                backdropFilter="blur(10px)"
                zIndex={-1}
            />

            {/* Logo */}
            <Image src={logo} alt="SENC eSports Logo" boxSize="150px" mb="2rem" />

            <Heading
                as={motion.h1}
                size="2xl"
                mb="2rem"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2, ease: 'easeInOut' }}
                style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '1rem', borderRadius: '10px' }}
            >
                Welcome to SENC eSports
            </Heading>
            <Flex justifyContent="center" gap="1rem">
                <Link as={RouterLink} to="/login">
                    <Button
                        m="1rem"
                        p="1rem 2rem"
                        bg="#ff8c00"
                        color="white"
                        borderRadius="5px"
                        fontSize="1.2rem"
                        _hover={{ bg: '#ffa500', transform: 'scale(1.05)' }}
                        transition="background-color 0.3s, transform 0.3s"
                        style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                    >
                        Login
                    </Button>
                </Link>
                <Link as={RouterLink} to="/register">
                    <Button
                        m="1rem"
                        p="1rem 2rem"
                        bg="#ff8c00"
                        color="white"
                        borderRadius="5px"
                        fontSize="1.2rem"
                        _hover={{ bg: '#ffa500', transform: 'scale(1.05)' }}
                        transition="background-color 0.3s, transform 0.3s"
                        style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                    >
                        Register
                    </Button>
                </Link>
            </Flex>

            {/* Introduction Section */}
            <Box mt="4rem" textAlign="left" maxW="800px" style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '1rem', borderRadius: '10px' }}>
                <Heading as="h2" size="lg" mb="1rem">
                    About SENC eSports
                </Heading>
                <Text fontSize="1.2rem" mb="2rem">
                    SENC eSports is a premier platform for competitive gaming. Join us to participate in exciting tournaments, connect with fellow gamers, and showcase your skills on a global stage.
                </Text>
            </Box>

            {/* Features Section */}
            <Box mt="4rem" textAlign="left" maxW="800px" style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '1rem', borderRadius: '10px' }}>
                <Heading as="h2" size="lg" mb="1rem">
                    Features
                </Heading>
                <VStack spacing="1rem" align="start">
                    <Text fontSize="1.2rem">🎮 Competitive Tournaments</Text>
                    <Text fontSize="1.2rem">🏆 Exciting Prizes</Text>
                    <Text fontSize="1.2rem">🌐 Global Community</Text>
                    <Text fontSize="1.2rem">📈 Leaderboards and Rankings</Text>
                </VStack>
            </Box>

            {/* Upcoming Events Section */}
            <Box mt="4rem" textAlign="left" maxW="800px" style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '1rem', borderRadius: '10px' }}>
                <Heading as="h2" size="lg" mb="1rem">
                    Ongoing Events
                </Heading>
                <VStack spacing="1rem" align="start">
                    {events.map(event => (
                        <HStack key={event.id} spacing="1rem">
                            <Image src={event.logo ? `https://api.senc.in/uploads/${event.logo}` : '/path/to/default.jpg'} alt={event.name} boxSize="100px" borderRadius="8px" />
                            <Box>
                                <Text fontSize="1.2rem" fontWeight="bold">{event.name}</Text>
                                <Text fontSize="1rem">Date: {new Date(event.registrationStart).toLocaleDateString()}</Text>
                            </Box>
                        </HStack>
                    ))}
                </VStack>
            </Box>

            {/* Testimonials Section */}
           
        </Box>
    );
};

export default Home;
