import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Heading, Image, Input, Text, VStack, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';

const ViewTournaments = () => {
    const [tournaments, setTournaments] = useState([]);
    const [registeredTournaments, setRegisteredTournaments] = useState([]);
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        teamName: '',
        teamLogo: null,
        phoneNumber: '',
        whatsappNumber: '',
        players: Array(6).fill({ name: '', inGameName: '', userId: '', serverId: '' }),
        tournamentId: ''
    });
    const [viewingBrackets, setViewingBrackets] = useState(false);
    const [matches, setMatches] = useState([]);
    const [bracketsOpen, setBracketsOpen] = useState({});

    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchTournaments = async () => {
            try {
                const response = await axios.get('https://api.senc.in/api/tournaments');
                const tournamentsData = response.data;

                // Assuming bracketsOpenMap is fetched or defined somewhere
                const bracketsOpenMap = tournamentsData.reduce((acc, tournament) => {
                    acc[tournament.id] = tournament.bracketOpen;
                    return acc;
                }, {});

                setTournaments(tournamentsData);
                setBracketsOpen(bracketsOpenMap);
            } catch (error) {
                console.error('Error fetching tournaments:', error);
            }
        };

        const fetchRegisteredTournaments = async () => {
            try {
                const response = await axios.get('https://api.senc.in/api/teams/registered');
                setRegisteredTournaments(response.data);
            } catch (error) {
                console.error('Error fetching registered tournaments:', error);
            }
        };

        fetchTournaments();
        fetchRegisteredTournaments();
    }, []);

    const handleRegisterClick = (tournamentId) => {
        if (registeredTournaments.includes(tournamentId)) {
            toast({
                title: 'Error',
                description: 'You are already registered for this tournament.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setFormData({ ...formData, tournamentId });
        onOpen();
        setViewingBrackets(false);
    };

    const handleViewBrackets = (tournamentId) => {
        navigate(`/view-brackets/${tournamentId}`);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'teamLogo') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const checkPhoneNumberExists = async (phoneNumber, tournamentId) => {
        try {
            const response = await axios.post('https://api.senc.in/api/teams/check-phone', {
                phoneNumber,
                tournamentId
            });

            return response.data.exists;
        } catch (error) {
            console.error('Error checking phone number:', error);
            return false;
        }
    };

    const handlePlayerChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPlayers = formData.players.map((player, i) =>
            i === index ? { ...player, [name]: value } : player
        );
        setFormData({ ...formData, players: updatedPlayers });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if phone number already exists
        const phoneNumberExists = await checkPhoneNumberExists(formData.phoneNumber, formData.tournamentId);
        if (phoneNumberExists) {
            toast({
                title: 'Error',
                description: 'A team with this phone number is already registered.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('teamName', formData.teamName);
        if (formData.teamLogo) {
            formDataToSend.append('teamLogo', formData.teamLogo);
        }
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('whatsappNumber', formData.whatsappNumber);
        formDataToSend.append('players', JSON.stringify(formData.players));
        formDataToSend.append('tournamentId', formData.tournamentId);

        try {
            const response = await axios.post('https://api.senc.in/api/teams/register', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage(response.data.message);
            toast({
                title: 'Success',
                description: 'Successfully registered for the tournament!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error('Error registering team:', error);
            setMessage('Error registering team');
        }
    };

    const isRegistrationClosed = (registrationEnd) => {
        const currentDate = new Date();
        const endDate = new Date(registrationEnd);
        return currentDate > endDate;
    };

    const isBracketOpen = (bracketOpen) => {
        return bracketOpen === 1;
    };

    return (
        <Container maxW="container.xl" py={4} pt={16} position="relative" zIndex={1}>
            {/* Background Video */}
            <video
                autoPlay
                loop
                muted
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                }}
            >
                <source src="https://docs.onlinehub.co.in/senchome.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <Heading as="h1" mb={4} color="gray.800">View Tournaments</Heading>
            {message && <Text color="green.500" textAlign="center" mb={4}>{message}</Text>}
            <VStack spacing={4}>
                {tournaments.map(tournament => (
                    <Box key={tournament.id} p={4} borderWidth="1px" borderRadius="lg" w="100%" maxW="600px" textAlign="center" bg="rgba(255, 255, 255, 0.1)" backdropFilter="blur(10px)" color="white">
                        <Heading as="h2" size="lg" mb={2} color="gray.100">{tournament.name}</Heading>
                        <Text><strong>Game:</strong> {tournament.game}</Text>
                        <Text><strong>Registration Start:</strong> {new Date(tournament.registrationStart).toLocaleString()}</Text>
                        <Text><strong>Registration End:</strong> {new Date(tournament.registrationEnd).toLocaleString()}</Text>
                        <Text><strong>Prize Pool:</strong> 💎{tournament.prizePool}</Text>
                        <Text><strong>Max Slots:</strong> {tournament.maxSlots}</Text>
                        <Text><strong>Filled Slots:</strong> {tournament.registered_teams}</Text>
                        <Text><strong>Description:</strong> {tournament.description}</Text>
                        {tournament.logo && <Image src={`https://api.senc.in/uploads/${tournament.logo}`} alt={`${tournament.name} logo`} my={4} />}
                        {isRegistrationClosed(tournament.registrationEnd) ? (
                            <Button colorScheme="gray" isDisabled>Closed</Button>
                        ) : (
                            <Button colorScheme="teal" onClick={() => handleRegisterClick(tournament.id)}>Register</Button>
                        )}
                        {isBracketOpen(tournament.bracketOpen) ? (
                            <Button colorScheme="blue" onClick={() => handleViewBrackets(tournament.id)}>View Brackets</Button>
                        ) : (
                            <Text color="red.500">Bracket not available yet</Text>
                        )}
                    </Box>
                ))}
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg="gray.800" color="white">
                    <ModalHeader>{viewingBrackets ? 'Tournament Brackets' : 'Register Team'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {viewingBrackets ? (
                            <div>
                                <h2>Tournament Brackets</h2>
                                {/* Use your TournamentBracket component here if needed */}
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <Input
                                    type="text"
                                    name="teamName"
                                    value={formData.teamName}
                                    onChange={handleChange}
                                    placeholder="Team Name"
                                    mb={3}
                                    required
                                    bg="gray.700"
                                    color="white"
                                />
                                <Input
                                    type="file"
                                    name="teamLogo"
                                    onChange={handleChange}
                                    mb={3}
                                    bg="gray.700"
                                    color="white"
                                />
                                <Input
                                    type="text"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    placeholder="Phone Number"
                                    mb={3}
                                    required
                                    bg="gray.700"
                                    color="white"
                                />
                                <Input
                                    type="text"
                                    name="whatsappNumber"
                                    value={formData.whatsappNumber}
                                    onChange={handleChange}
                                    placeholder="WhatsApp Number"
                                    mb={3}
                                    required
                                    bg="gray.700"
                                    color="white"
                                />
                                {formData.players.map((player, index) => (
                                    <Box key={index} mb={3}>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={player.name}
                                            onChange={(e) => handlePlayerChange(index, e)}
                                            placeholder={`Player ${index + 1} Name`}
                                            mb={2}
                                            required={index < 5}
                                            bg="gray.700"
                                            color="white"
                                        />
                                        <Input
                                            type="text"
                                            name="inGameName"
                                            value={player.inGameName}
                                            onChange={(e) => handlePlayerChange(index, e)}
                                            placeholder="In-Game Name"
                                            mb={2}
                                            required={index < 5}
                                            bg="gray.700"
                                            color="white"
                                        />
                                        <Input
                                            type="text"
                                            name="userId"
                                            value={player.userId}
                                            onChange={(e) => handlePlayerChange(index, e)}
                                            placeholder="User ID"
                                            mb={2}
                                            required={index < 5}
                                            bg="gray.700"
                                            color="white"
                                        />
                                        <Input
                                            type="text"
                                            name="serverId"
                                            value={player.serverId}
                                            onChange={(e) => handlePlayerChange(index, e)}
                                            placeholder="Server ID"
                                            mb={2}
                                            required={index < 5}
                                            bg="gray.700"
                                            color="white"
                                        />
                                    </Box>
                                ))}
                                <Button type="submit" colorScheme="teal" mt={4}>Submit</Button>
                            </form>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default ViewTournaments;
